<template>
  <div class="container">
    <div class="top-header" v-show="!showEnd"></div>
    <div class="video d-flex align-items-center po-r" v-show="showEnd">
      <div class="video-mask shadow-top po-a"></div>
      <div
        class="play-btn p-a-c d-flex align-items-center justify-content-center"
        v-show="playShow"
        @click="play"
      >
        <img src="@/assets/play.png" alt="" />
      </div>
      <div class="video-mask shadow-bottom po-a">
        <div class="icon po-a">
          <div class="icon-item" @click="changeMask">
            <img id="author-img" :src="videoDetailData.authorImage" alt="" />
          </div>
          <div
            class="icon-item"
            @click="changeMask"
            v-if="
              videoDetailData.questionList && videoDetailData.questionList[0]
            "
          >
            <img src="@/assets/practice.svg" alt="" />
            <span>练习</span>
          </div>
          <div class="icon-item" @click="changeMask">
            <img src="@/assets/red.png" v-show="showLike" alt="" />
            <img src="@/assets/fff.png" v-show="!showLike" alt="" />
            <span class="">{{ videoDetailData.likeCount }}</span>
          </div>
          <div class="icon-item" @click="changeMask">
            <img src="@/assets/share.png" alt="" />
            <span>分享</span>
          </div>
        </div>
        <!-- <div class="btn-topic po-a" >
          <span>练习题</span>
        </div> -->
        <div class="info po-a">
          <div class="name">@{{ videoDetailData.authorName }}</div>
          <div class="tips">
            {{ videoDetailData.describe }}
          </div>
        </div>
      </div>

      <!--  -->

      <div class="video-box">
        <video
          :src="videoDetailData.url"
          :poster="videoDetailData.cover"
          preload
          id="refVideo"
          @click="play"
          @ended="ended"
          controlslist="nodownload"
          x-webkit-airplay="allow"
          webkit-playsinline="true"
          x5-playsinline="true"
          x5-video-player-type="h5"
          x5-video-player-fullscreen="false"
          playsinline="true"
          preload="auto"
          x5-video-orientation="h5"
        >
          此浏览器不支持video标签
        </video>
      </div>
      <!-- <div class="po-r" style="z-index: 5"> -->

      <!-- </div> -->
    </div>
    <div class="video-end po-r" v-show="!showEnd">
      <div class="video-end-box po-a">
        <div class="swiper_all">
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(item, idx) in list"
                :key="idx"
                @click="changeMask"
              >
                <div class="swiper-info po-r">
                  <div class="d-flex align-items-center img">
                    <img :src="item.cover" alt="" />
                  </div>
                  <div
                    class="
                      play-btn
                      p-a-c
                      d-flex
                      align-items-center
                      justify-content-center
                    "
                  >
                    <img src="@/assets/play.png" alt="" />
                  </div>
                  <div class="black-mask po-a">
                    <div class="shadow po-a">
                      <!-- <img src="@/assets/shadow.png" alt="" /> -->
                    </div>
                    <div class="tips d-flex align-items-center po-a">
                      <img src="@/assets/fff.png" alt="" />
                      <span>{{ item.likeCount }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="btn-box d-flex align-items-center justify-content-center">
          <div
            class="
              btn-box-item
              d-flex
              align-items-center
              justify-content-center
            "
            @click="changeMask"
          >
            <img src="@/assets/ref.png" alt="" />
            <span>再看一遍</span>
          </div>
          <div
            class="
              btn-box-item
              d-flex
              align-items-center
              justify-content-center
            "
            @click="download"
          >
            <span>打开洋葱韩语</span>
          </div>
        </div>
      </div>
    </div>
    <downBtn
      bg="fff"
      :topShow="true"
      :bottomShow="false"
      :isWx="isWx"
      @down="download"
      @wechatShow="wechatShow"
    ></downBtn>
    <div class="mask-box pos-fiexd" v-show="maskShow">
      <!-- <div class="mask"></div> -->
      <div class="mask-content p-a-c bg-fff">
        <div class="close text-right">
          <img
            src="@/assets/close.png"
            alt=""
            style="width: 14px; height: 14px"
            @click="changeMask"
          />
        </div>
        <div class="content text-center">
          <div class="text">下载洋葱韩语app</div>
          <div class="btn" @click="download">
            <span>去下载</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import downBtn from "@/components/downBtn";
import mixin from "@/utils/mixin";
import Swiper from "swiper/swiper-bundle.min.js";
import "swiper/swiper-bundle.min.css";
import { videoList, videoDetail } from "@/api/video";
import { setTimeout } from "timers";
export default {
  name: "",
  data() {
    return {
      playShow: true,
      video: "",
      showLike: false,
      showEnd: true,
      list: [],
      param: {
        current: 1,
        size: 5,
      },
      videoDetailData: {},
      maskShow: false,
    };
  },
  mixins: [mixin],
  components: {
    downBtn,
  },
  created() {},
  mounted() {
    // if (this.$route.query.id) {
    this.videoDetail({
      videoId: this.$route.query.id,
    });
    this.videoList(this.param);
    // }
  },
  methods: {
    changeMask() {
      console.log(1);
      this.maskShow = !this.maskShow;
    },
    formatNumber(num) {
      return num >= 1e3 && num < 1e4
        ? (num / 1e3).toFixed(1) + "k"
        : num >= 1e4
        ? (num / 1e4).toFixed(1) + "w"
        : num;
    },
    async videoDetail(id) {
      const { data, code } = await videoDetail(id);
      if (code != "SUCCESS") return;
      data.likeCount = this.formatNumber(data.likeCount);
      this.videoDetailData = data;
    },
    async videoList(list) {
      const { data, code } = await videoList(list);
      if (code != "SUCCESS") return;
      data.dates.forEach((item) => {
        item.likeCount = this.formatNumber(item.likeCount);
      });
      this.list = data.dates;
      setTimeout(() => {
        let _this = this;
        new Swiper(".swiper-container", {
          loop: false, // 循环模式选项
          loopedSlides: 2, // 必设不然最后一张右边没元素
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          observeParents: true, //修改swiper的父元素时，自动初始化swiper
          coverflow: {
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          },
        });
      }, 200);
    },
    like() {
      // this.showLike = !this.showLike
    },
    play() {
      this.video = document.getElementById("refVideo");
      // this.video.play()

      if (this.playShow) {
        this.video.play();
      } else {
        this.video.pause();
      }
      this.playShow = !this.playShow;
    },
    ended() {
      this.playShow = !this.playShow;
      this.showEnd = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  /* background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(51, 51, 51, 1) 74%
  ); */
  background: #151724;
  width: 100wh;
  height: 100vh;
  overflow: hidden;

  .top-header {
    height: 40px;
    width: 100%;
  }
  .video {
    width: 100%;
    height: 100vh;
    .video-box {
      width: 100%;

      video {
        width: 100%;
      }
    }
    &-mask {
      left: 0;
      right: 0;

      z-index: 2;
      &.shadow-top {
        top: 0;
        height: 120px;
        background: linear-gradient(
          180.75deg,
          rgba(0, 0, 0, 0.4) 0.64%,
          rgba(255, 255, 255, 0) 99.36%
        );
      }
      &.shadow-bottom {
        bottom: 0;
        height: 270px;
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.4) 0.64%,
          rgba(255, 255, 255, 0) 99.36%
        );
      }
    }
    .play-btn {
      border-radius: 100%;
      width: 83px;
      height: 83px;
      background: rgba(0, 0, 0, 0.32);
      // background: #ccc;
      img {
        width: 30px;
        margin-left: 6px;
      }
    }
    .icon {
      right: 16px;
      bottom: 106px;
      &-item {
        text-align: center;
        margin-bottom: 25px;
        img {
          width: 28px;
        }
        span {
          // text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.6);
          color: #fff;
          display: block;
          font-size: 14px;
          line-height: 20px;
          margin-top: 5px;
        }
      }
    }
    .btn-topic {
      left: 50%;
      bottom: 188px;
      transform: translateX(-50%);
      border: 1px solid rgba(69, 69, 69, 0.31);
      box-sizing: border-box;
      border-radius: 24px;
      // width: 116px;
      // height: 42px;
      span {
        color: #fff;
        font-size: 16px;
        display: block;
        width: 110px;
        // height: 36px;
        background: rgba(0, 0, 0, 0.37);
        // background: rgba(255, 255, 255, 0.74);
        border-radius: 24px;
        margin: 1px;
        text-align: center;
        padding: 10px 0;
      }
    }
    .info {
      bottom: 16px;
      left: 16px;
      width: 280px;
      color: #fff;
      height: 130px;
      .name {
        margin-bottom: 6px;
        font-size: 20px;

        // text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.6);
      }
      .tips {
        line-height: 28px;
        font-size: 16px;

        // text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.6);
      }
    }
  }
  .video-end {
    height: 100%;
    width: 100%;

    &-box {
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      overflow: hidden;
      .swiper_all {
        overflow: hidden;

        .swiper-container {
          overflow: visible !important;
          width: 276px;
          height: 368px;
          margin: 0 auto;
          .swiper-wrapper {
            .swiper-slide {
              border-radius: 6px;
              overflow: hidden;
              background: #fff;
              &:first-child {
                margin-left: -8px;
              }
              &:last-child {
                // margin-right: -16px;
              }
            }
            .swiper-slide-active {
              margin: 0 16px;
            }
            .swiper-slide-prev {
              // margin: 0 0 0 -16px;
            } /*由于swiper-slide-active左右设置了16像素，默认往右边挤过去了单独给前一个div设置负16像素左右才能对齐*/
            .swiper-info {
              // padding: 8px 16px;
              height: 100%;
              .img {
                height: 100%;
                img {
                  width: 276px;
                }
              }
              .play-btn {
                width: 54px;
                height: 54px;
                border-radius: 50%;
                box-sizing: border-box;
                background: rgba(0, 0, 0, 0.32);
                img {
                  width: 24px;
                  margin-left: 4px;
                }
              }
              .black-mask {
                bottom: 0;
                left: 0;
                right: 0;
                // height: 112px;
                width: 100%;
                box-sizing: border-box;
                /* background: linear-gradient(
                180deg,
                rgba(255, 255, 255, 0) 0%,
                #c7c7c7 100%
              ); */
                border-radius: 0px 0px 6px 6px;
                .shadow {
                  bottom: 0;
                  // overflow: hidden;
                  width: 100%;
                  height: 107px;
                  background: linear-gradient(
                    0deg,
                    rgba(0, 0, 0, 0.4) 0.64%,
                    rgba(255, 255, 255, 0) 99.36%
                  );

                  img {
                    width: 100%;
                    display: block;
                    vertical-align: bottom;
                  }
                }
                .tips {
                  bottom: 12px;
                  right: 16px;
                  img {
                    width: 20px;
                  }
                  span {
                    display: block;
                    margin-left: 8px;
                    color: #fff;
                    font-size: 14px;
                    line-height: 20px;
                  }
                }
              }
            }
          }
        }
        .swiper-pagination {
          top: 0;
          height: 31px;
          /deep/ .swiper-pagination-bullet {
            width: 25px;
            height: 25px;
            // background: #c4c4c4;
            margin: 0 8px;
            opacity: 0;
            border-radius: 0;
            &.swiper-pagination-bullet-active {
              // background: #02897a !important;
              opacity: 0;
            }
          }
        }
      }
      .btn-box {
        margin-top: 80px;
        margin-bottom: 40px;
        color: #fff;
        font-size: 14px;
        &-item {
          width: 118px;
          height: 46px;
          box-sizing: border-box;
          border-radius: 23px;
          border: 1px solid #ffffff;
          margin: 0 20px;
          &:last-child {
            background: #4e6aff;
            border-color: #4e6aff;
            span {
              margin-left: 0;
            }
          }
          img {
            width: 20px;
          }
          span {
            margin-left: 8px;
          }
        }
      }
    }
  }
}
.icon-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  #author-img {
    width: 40px !important;
    height: 40px !important;
    border-radius: 50%;
  }
}
</style>
