import api from '@/utils/request'

// 视频列表
// /app/video/videoList
export function videoList(data) {
  return api({
    method: 'post',
    url: '/app/video/videoList',
    data,
  })
}

// 视频详情
// /app/video/videoDetail
export function videoDetail(data) {
  return api({
    method: 'post',
    url: '/app/video/videoDetail',
    data,
  })
}
